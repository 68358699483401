import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      component: () => import( "@/view/layout"),
      children:[
        {
          path: '/',
          name: 'home',
          component: () => import( "@/view/home")
        },
        {
          path: '/intelligent',
          name: 'intelligent',
          component: () => import( "@/view/intelligent")
        },
        {
          path: '/robot',
          name: 'robot',
          component: () => import( "@/view/robot")
        },
        {
          path: '/content',
          name: 'content',
          component: () => import( "@/view/content")
        },
        {
          path: '/business',
          name: 'business',
          component: () => import( "@/view/business"),
        },
        {
          path: '/about',
          name: 'about',
          component: () => import( "@/view/about")
        },
        {
          path: '/join',
          name: 'join',
          component: () => import( "@/view/join")
        },
        {
          path: '/news',
          name: 'news',
          component: () => import( "@/components/news/detail")
        },
      ]
    }
  ]
})

export default router
