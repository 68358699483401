import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store"

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import 'animate.css'
import '@/assets/iconfont/iconfont.css'
import '@/assets/css/main.css'

import less from 'less'
Vue.use(less)

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
// import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */)

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

import VueWow from 'vue-wow'
Vue.use(VueWow)
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
