import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    // 判断是否为移动端
    isMobile: false,
    // 判断屏幕宽度
    clientWidth: 1200
  },
  getters: {
    isMobile: state => state.isMobile,
    clientWidth: state => state.clientWidth
  },
  mutations: {
    set_isMobile(state, data) {
      state.isMobile = data;
    },
    set_clientWidth(state, data) {
      state.clientWidth = data;
    }
  }
})
export default store
